
<PageTemplate
  :useCard="false"
  :breadcrumbs="[
    {
      text: 'Metering',
    },
  ]"
>
  <MeteringPricing />
</PageTemplate>
