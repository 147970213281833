
<div class="w-full rde-usage-wrapper flex flex-row gap-2">
  <div class="w-full">
    <div class="flex justify-between items-center panel-title">
      <h3 class="table-title pb-1">RDE Usage Overview</h3>
      <v-text-field
        v-model="searchKey"
        label="Filter"
        outlined
        dense
        clearable
        append-icon="mdi-filter"
        class="app-g-autocomplete-v2 flex-none"
        @click:append="searchKey = ''"
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="sortedItems"
      :hide-default-header="true"
      item-key="workspaceId"
      class="elevation-1 rde-usage-table w-full"
      :search="searchKey"
    >
      <template v-slot:header="{ props }">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="header-cell"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item, index }">
        <tr
          v-for="(usage, i) in item.usedOverview"
          :key="`usage-${item.rdeId + i}`"
          class="row-item"
          @click="onRowClick(item)"
        >
          <td v-if="i === 0" :rowspan="item.usedOverview.length">
            {{ item.rdeDisplayName }}
          </td>
          <td
            v-if="i === 0"
            :rowspan="item.usedOverview.length"
            :title="item.workspaceDisplayName"
          >
            {{ $helper.truncatedText(item.workspaceDisplayName, 40) }}
          </td>
          <td v-if="i === 0" :rowspan="item.usedOverview.length">
            {{ $helper.showDate(item.createdDate) }}
          </td>
          <td v-if="i === 0" :rowspan="item.usedOverview.length">
            {{ item.username }}
          </td>
          <td>{{ usage.instanceType }}</td>
          <td>{{ $helper.viewCpuData(usage.cpu) }}</td>
          <td>{{ $helper.viewMemoryData(usage.memory) }}</td>
          <td>
            {{ $helper.roundX(usage.disk || 0, 0) }}{{ usage.diskUnit }}
          </td>
          <td>{{ $helper.minutesToReadable(usage.estimatedTime) }}</td>
        </tr>
        <tr
          v-if="item.usedOverview.length === 0"
          :key="`no-usage-${index}`"
          class="row-item"
          @click="onRowClick(item)"
        >
          <td>{{ item.rdeDisplayName }}</td>
          <td :title="item.workspaceDisplayName">
            {{ $helper.truncatedText(item.workspaceDisplayName, 40) }}
          </td>
          <td>{{ $helper.showDate(item.createdDate) }}</td>
          <td>{{ item.username }}</td>
          <td colspan="5" class="text-center">No usage data</td>
        </tr>
      </template>
      <template v-slot:no-data>
        <div class="flex justify-center items-center h-20">
          No data available
        </div>
      </template>
    </v-data-table>
  </div>
  <div v-if="selectedRde" class="w-full">
    <div class="flex justify-between items-center panel-title">
      <h3 class="table-title pb-1">
        RDE Usage Detail:
        <v-chip small color="primary">
          {{ selectedRde?.rdeDisplayName }}
        </v-chip>
      </h3>
      <span
        class="text-sm text-primary cursor-pointer pr-2"
        @click="() => (selectedRde = null)"
        >Hide</span
      >
    </div>
    <v-data-table
      :headers="headersDetail"
      :items="rdeUsageDetail"
      :hide-default-header="true"
      item-key="rdeId"
      class="elevation-1 rde-usage-table w-full"
      :loading="loadingDetail"
    >
      <template v-slot:header="{ props }">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="header-cell"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.instanceType }}</td>
          <td>{{ $helper.viewCpuData(item.cpu) }}</td>
          <td>{{ $helper.viewMemoryData(item.memory) }}</td>
          <td>{{ $helper.roundX(item.disk || 0, 0) }} {{ item.diskUnit }}</td>
          <td>{{ item.storageType }}</td>
          <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
          <td>{{ $helper.showDate(item.updated) }}</td>
          <td>{{ $helper.showDate(item.endTime) }}</td>
        </tr>
      </template>
      <template v-slot:no-data>
        <div class="flex justify-center items-center h-20">
          No data available
        </div>
      </template>
    </v-data-table>
  </div>
</div>
