import httpClient from "../httpClient";
import { request } from "../request";

const pipelineApiPack = {
  getPipelinesByProfileId(profileId) {
    //SKCPL-796 profileId사용
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/query?profileId=${profileId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getPipelinesByMicroserviceId(microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/query?microserviceId=${microserviceId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getAppstorePipelines() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/query?appstore=true`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getPipelineDetail(pipelineId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/query/${pipelineId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  createPipeline(projectId, microserviceId, profileId, data) {
    return new Promise((resolve, reject) => {
      projectId = "" + projectId;
      microserviceId = "" + microserviceId;
      profileId = "" + profileId;
      const payload = { projectId, microserviceId, profileId, ...data };
      httpClient
        .post(`/v1/pipeline/create`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  savePipeline(pipelineId, pipelineDetail) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/pipeline/query/${pipelineId}`, pipelineDetail)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getPipelineCodeTemplates() {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/template`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getProjectCodeTemplates(profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/template?profileId=${profileId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getPipelineCodeTemplate(templateId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/template/${templateId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  savePipelineCodeTemplate(templateId, template) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/pipeline/template/${templateId}`, template)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  createPipelineCodeTemplate(template) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/template`, template)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  // copyPipelineCodeTemplateForProject(templateId, projectId) {
  //   return new Promise((resolve, reject) => {
  //     httpClient.post(`/v1/pipeline/template/${templateId}/copy/project/${projectId}`)
  //     .then(res=>{
  //       resolve(res.data)
  //     }).catch(err=> {
  //       reject(err.response)
  //     })
  //   })
  // },
  deletePipelineCodeTemplate(templateId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/pipeline/template/${templateId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  // deprecated
  // getGeneratedPipelineCode(pipelineId, filename) {
  //   let url = `/v1/pipeline/query/${pipelineId}/code`;
  //   if (filename) {
  //     url += "?filename=" + encodeURIComponent(filename);
  //   }
  //   return new Promise((resolve, reject) => {
  //     httpClient
  //       .get(url)
  //       .then((res) => {
  //         resolve(res.data);
  //       })
  //       .catch((err) => {
  //         reject(err.response);
  //       });
  //   });
  // },

  //SKCPL-633 패치버전 기능 지원
  registerPipelineJob(pipelineId, filename, isCI) {
    let payload = {
      filename: filename,
    };

    if (isCI) {
      payload.tekton = true;
    } else {
      payload.argocd = true;
    }

    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/query/${pipelineId}/code/registerjob`, payload, {
          timeout: 2 * 60 * 1000,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getPipelineRegisterJobDetail(pipelineId, registerJobId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/pipeline/query/${pipelineId}/code/registerjob/${registerJobId}`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  cancelTektonPipelineRun(pipelineId, pipelineRunName) {
    let url = `/v1/pipeline/query/${pipelineId}/tekton/cancelpr?name=${pipelineRunName}`;
    return new Promise((resolve, reject) => {
      httpClient
        .put(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  //백그라운드 실행상태 확인과 무관한 db상 강제 취소를 진행한다.
  forceCancelCommitRun(pipelineId, commitRunId) {
    let url = `/v1/pipeline/query/${pipelineId}/code/commitrun/${commitRunId}/forcecancel`;
    return new Promise((resolve, reject) => {
      httpClient
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  commitrunPipelineCode(
    pipelineId,
    filename,
    fullSemVer,
    patchVersion,
    argocd,
    tekton,
    cdChaining,
    scriptName,
  ) {
    let url = `/v1/pipeline/query/${pipelineId}/code/commitrun`;
    let param = {};
    let timeout = 2 * 60 * 1000;
    if (filename) {
      param.filename = filename;
    }

    if (argocd) {
      param.argocd = argocd;
    }

    if (tekton) {
      param.tekton = tekton;
    }

    if (cdChaining) {
      if (cdChaining.jenkins) {
        param.chainCDJenkins = cdChaining.jenkins;
      }
      if (cdChaining.argocd) {
        param.chainCDArgoCD = cdChaining.argocd;
      }
      if (cdChaining.tekton) {
        param.chainCDTekton = cdChaining.tekton;
      }
    }

    if (typeof fullSemVer == "string" && fullSemVer) {
      param = { ...param, fullSemVer };
    }

    if (typeof patchVersion == "string" && patchVersion) {
      param = { ...param, patchVersion };
    }

    if (scriptName) {
      param.scriptName = scriptName;
    }

    return new Promise((resolve, reject) => {
      httpClient
        .post(url, param, { timeout })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getPipelineCommitRunDetail(pipelineId, commitrunId) {
    let url = `/v1/pipeline/query/${pipelineId}/code/commitrun/${commitrunId}`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getPipelineCodeRunHistory(pipelineId, filename, page, perPage) {
    let url = `/v1/pipeline/query/${pipelineId}/code/commitrun`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url, { params: { filename, page, perPage } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getPipelineHistoryByRole(pipelineId, role, page, perPage) {
    let url = `/v1/pipeline/query/${pipelineId}/code/commitrun`;
    return new Promise((resolve, reject) => {
      httpClient
        .get(url, { params: { role, page, perPage } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  updateCommitrunApproved(pipelineId, commitrunId, approved) {
    let url = `/v1/pipeline/query/${pipelineId}/code/commitrun/${commitrunId}/approved`;
    return new Promise((resolve, reject) => {
      httpClient
        .put(url, { approved })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  getProfilePipelineMeta(profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/profilemeta/${profileId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  saveProfilePipelineMeta(profileId, meta) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/pipeline/profilemeta/${profileId}`, meta)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  createProfilePipelineMeta(projectId, profileId, meta) {
    const obj = Object.assign({}, meta);
    obj.projectId = String(projectId);
    obj.profileId = String(profileId);
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/profilemeta`, obj)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  activatePipelineCodeTemplate(pipelineId, type, name, activated, custom) {
    let param = { type, name, activated };
    if (custom && typeof custom == "object") {
      param = { ...custom, ...param };
    }
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/query/${pipelineId}/activate`, param)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  //SKCPL-703 프로파일메타 복제기능
  //XXX SKCPL-796 복제된 데이터에 source profile id 값 없음
  createProfilePipelineMetaByCopy(projectId, profileId, sourceProfileId) {
    const payload = {};
    payload.projectId = String(projectId); //생성하려는 메타가 소속된 프로젝트,프로파일
    payload.profileId = String(profileId);
    return new Promise((resolve, reject) => {
      //복제하려는 원본 프로젝트 아이디는 여기에서 세팅
      httpClient
        .post(
          `/v1/pipeline/profilemeta/${profileId}/copy/profile/${sourceProfileId}`,
          payload,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  //XXX SKCPL-796 복제된 데이터에 source profile id 값 없음
  createProjectPipelinesByCopy(
    projectId,
    profileId,
    sourceProjectId,
    sourceProfileId,
  ) {
    const payload = {};
    payload.projectId = String(projectId); //생성하려는 메타가 소속된 프로젝트,프로파일
    payload.profileId = String(profileId); //이거 백엔드에서 안씀. 프로젝트 메타가 모두 생성되어 있음을 가정하고 있으므로.
    payload.sourceProjectId = String(sourceProjectId);
    payload.sourceProfileId = String(sourceProfileId);
    return new Promise((resolve, reject) => {
      //복제하려는 원본 프로젝트 아이디는 여기에서 세팅
      httpClient
        .post(
          `/v1/pipeline/profilemeta/${profileId}/copy/microservice/${sourceProfileId}`,
          payload,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getMicroserviceContainerImageTags(projectId, profileId, microserviceId) {
    // /verify/imagetags/project/:projectId/profile/:profileId/microservice/:microserviceId
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/pipeline/verify/imagetags/project/${projectId}/profile/${profileId}/microservice/${microserviceId}`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  //SKCPL-728 파이프라인 스케쥴기반 실행
  getPipelineSchedule(pipelineId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/query/${pipelineId}/schedule`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  updatePipelineSchedule(pipelineId, schedule) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/pipeline/query/${pipelineId}/schedule`, schedule)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  //SKCPL-773 프로파일 코드템플릿 사용성 개선위해
  //코드템플릿 복제기능 배제하고 API호출 단순화
  getProfileDedicatedCodeTemplate(profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/profilemeta/${profileId}/codetemplate`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  //프로젝트 내에서 활성화된 파이프라인만 조회해옴
  getProfileActivatedPipelines(profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/profilemeta/${profileId}/activatedPipelines`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getTektonLog(pipelineId, pipelinerun, pod, container) {
    return new Promise((resolve, reject) => {
      let url = `/v1/pipeline/query/${pipelineId}/tekton/logs?`;
      url += `pipelinerun=${pipelinerun}&`;
      url += `pod=${pod}&`;
      url += `container=${container}&`;
      httpClient
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  //파이프라인 웹훅 처리
  getWebhookPolicyList(pipelineId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/query/${pipelineId}/webhook`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  createWebhookPolicy(pipelineId, payload) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/pipeline/query/${pipelineId}/webhook`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  updateWebhookPolicyList(pipelineId, list) {
    return new Promise((resolve, reject) => {
      httpClient
        .put(`/v1/pipeline/query/${pipelineId}/webhook`, { list })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  deleteWebhookPolicy(pipelineId, webhookId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v1/pipeline/query/${pipelineId}/webhook/${webhookId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  pipelineQueryStatusAll(microserviceIds, cirole, cdrole) {
    const role = [];
    if (cirole) role.push("ci");
    if (cdrole) role.push("cd");
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/v1/pipeline/queryall/status`,
          { microserviceIds, role },
          { timeout: 60 * 1000 },
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  microservicePipelineStatus(microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/queryall/microservice/${microserviceId}`, null, {
          timeout: 60 * 1000,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getMicroserviceBuiltImageTags(microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/queryall/microservice/${microserviceId}/buildtags`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getMicroserviceDeployImageTags(microserviceId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/pipeline/queryall/microservice/${microserviceId}/deploytags`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  //테스트결과 취합
  getCommitrunTestSummary(pipelineId, commitrunId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/pipeline/query/${pipelineId}/code/commitrun/${commitrunId}/testSummary`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  // Menu : Applicaion > Profile > History Tab
  // 기간 관계 없이 전체 히스토리 추출
  // 예시. [엑셀 저장] 버튼 클릭 시
  getPipelineHistoryByProjectIdAndProfileIdAndMicroserviceIdsAndRolesDate(
    projectId,
    profileId,
    microserviceIds,
    roles,
    startDate,
    endDate,
  ) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/pipeline/query/projectId/${projectId}/profileId/${profileId}/buildDeployHistory?microserviceIds=${microserviceIds}&roles=${roles}&startDate=${startDate}&endDate=${endDate}`,
          {
            timeout: 180000, // 최대 3분 타임아웃 설정
          },
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // Menu : Applicaion > Profile > History Tab
  // 기간, 페이지 기준 히스토리 추출 (테이블 노출)
  getPipelineHistoryByProjectIdAndProfileIdAndMicroserviceIdsAndRolesAndPageAndDate(
    projectId,
    profileId,
    microserviceIds,
    roles,
    page,
    itemPerPage,
    startDate,
    endDate,
  ) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/v1/pipeline/query/projectId/${projectId}/profileId/${profileId}/buildDeployHistory?microserviceIds=${microserviceIds}&roles=${roles}&page=${page}&perPage=${itemPerPage}&startDate=${startDate}&endDate=${endDate}`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
};

export default pipelineApiPack;

export const restartDeployPipeline = ({
  pipelineID,
  lastCommitRunID,
  timestamp,
}) => {
  return request({
    method: "post",
    uri: `/v1/pipeline/query/${pipelineID}/code/commitrun/${lastCommitRunID}/restart`,
    query: { timestamp },
    timeout: 1000 * 120,
  });
};

export const clonePipeline = ({ sourceApplicationId, data }) => {
  return request({
    method: "post",
    uri: `/v1/pipeline/microservice/${sourceApplicationId}/clone`,
    data,
    timeout: 1000 * 120,
  });
};
