<template>
  <div>
    <!-- Row 1: Label and dropdown box -->
    <div class="row my-0">
      <div class="col columns">
        <!-- Label on the left -->
      </div>
      <div class="col columns text-right">
        <!-- Dropdown box on the right -->
        <DateRangePicker
          v-model="dateRange"
          opens="left"
          :genRanges="genRanges"
          :max-duration-start-end="0"
          viewFormat="DD/MM/YYYY"
          class="w-auto ml-auto"
          :timePicker="false"
        />
      </div>
    </div>
    <!-- Row 2: Two multi-select components -->
    <div v-if="!showMyRde" class="row my-0">
      <div class="col py-0 px-0">
        <!-- Left multi-select component -->
        <v-autocomplete
          v-model="selectedWorkspaces"
          :items="workspaceItems"
          placeholder="Select left item"
          class="app-g-autocomplete-v2"
          label="Workspaces"
          item-text="workspaceDisplayName"
          item-value="workspaceId"
          multiple
          outlined
          dense
          chips
          clearable
          deletable-chips
          small-chips
        >
        </v-autocomplete>
      </div>
      <div class="col py-0 px-0">
        <!-- Right multi-select component -->
        <v-autocomplete
          v-model="selectedUsers"
          :items="userItems"
          placeholder="Please select the workspace first"
          class="app-g-autocomplete-v2"
          :label="
            selectedWorkspaces.length === 0
              ? 'Please select the workspace first'
              : 'Users'
          "
          multiple
          outlined
          dense
          chips
          clearable
          deletable-chips
          small-chips
          :disabled="selectedWorkspaces.length === 0"
        />
      </div>
    </div>
    <div class="row">
      <div class="col py-0 px-0 columns">
        <OverviewTable :loading="loading" :items="workspaceOverviewItems" />
      </div>
    </div>
    <!-- Row 3: Table -->
    <div class="row">
      <WorkspaceUsageTable :loading="loading" :items="workspaceOverviewItems" />
    </div>

    <!-- Row 4: Table with pagination -->
    <div v-if="rdeUsageOverview?.length > 0" class="row">
      <RdeUsageTable
        :loading="loading"
        :items="rdeUsageOverview"
        :dateRange="dateRange"
      />
    </div>
  </div>
</template>

<script>
import WorkspaceUsageTable from "./components/WorkspaceUsageTable.vue";
import RdeUsageTable from "./components/RdeUsageTable.vue";
import OverviewTable from "./components/OverviewTable.vue";

import { DEFAULT_VALUE_RANGE_PICKER } from "@/pages/dashboard/constant";
import { genAgoTime } from "@/pages/dashboard/helper";
import DateRangePicker from "@/components/atoms/DateRangePicker/DateRangePicker.vue";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";
import * as _ from "lodash";
import moment from "moment";
import {
  getWorkspaceOverview,
  getRdeUsageOverview,
  getWorkspacesAndUsers,
} from "@/service/apis/workspaceApis";
import { mapState } from "vuex";

const RANGES_DEFAULT_DATETIME_PICKER = () => {
  // Get the current date and time
  const currentDate = new Date();

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  return {
    "Last 2 weeks": [genAgoTime(20160), currentDate],
    Today: [today, currentDate],
    Yesterday: [yesterday, today],
    "Last 1 month": [genAgoTime(43200), currentDate],
    "Last 3 months": [genAgoTime(129600), currentDate],
    "Last 6 months": [genAgoTime(259200), currentDate],
    "Last 12 months": [genAgoTime(518400), currentDate],
  };
};

export default {
  name: "MeteringPricing",
  components: {
    WorkspaceUsageTable,
    RdeUsageTable,
    DateRangePicker,
    OverviewTable,
  },
  mixins: [ProfileAuthMixin],
  data() {
    return {
      selectedWorkspaces: [],
      selectedUsers: [],
      dropdownValue: null,
      searchQuery: "",
      userItems: [],
      workspaceItems: [],
      genRanges: RANGES_DEFAULT_DATETIME_PICKER,
      dateRange: {
        startDate: genAgoTime(DEFAULT_VALUE_RANGE_PICKER * 24 * 60),
        endDate: new Date(),
      },
      inputSearch: "",
      workspaceOverviewItems: [],
      rdeUsageOverview: [],
      masterData: [],
      loading: false,
      showMyRde: false,
    };
  },
  computed: {
    ...mapState({
      userName: (state) => state.accountInfo.username,
    }),
  },
  watch: {
    selectedWorkspaces: {
      handler() {
        this.collectUsers();
        if (this.selectedWorkspaces.length > 0) {
          this.debounceInput();
        } else {
          this.workspaceOverviewItems = [];
          this.rdeUsageOverview = [];
        }
      },
      deep: true,
    },
    selectedUsers: {
      handler() {
        this.debounceInput();
      },
      deep: true,
    },
    dateRange: {
      handler(newVal, oldVal) {
        if (
          _.isEqual(newVal, oldVal) ||
          !newVal?.startDate ||
          !newVal?.endDate ||
          this.selectedWorkspaces.length === 0
        ) {
          return;
        }
        this.debounceInput();
      },
      deep: true,
    },
  },
  methods: {
    debounceInput: _.debounce(function () {
      this.handleSearch();
    }, 800),
    async handleSearch() {
      this.loading = true;
      const userNames = this.selectedUsers.map((user) => user).join(",");
      const workspaceIds = this.selectedWorkspaces
        .map((workspace) => workspace)
        .join(",");
      const from = moment(this.dateRange.startDate).format("YYYY-MM-DD");
      const to = moment(this.dateRange.endDate).format("YYYY-MM-DD");

      try {
        const wsRes = await getWorkspaceOverview({
          userNames,
          workspaceIds,
          from,
          to,
        });
        const dataArr = wsRes?.data?.data;
        this.workspaceOverviewItems = dataArr.sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });

        if (this.workspaceOverviewItems.length > 0) {
          const rdeRes = await getRdeUsageOverview({
            userNames,
            workspaceIds,
            from,
            to,
          });
          this.rdeUsageOverview = rdeRes?.data?.data;
        } else {
          this.rdeUsageOverview = [];
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    async getWorkspacesAndUsers() {
      getWorkspacesAndUsers(this.userName).then((res) => {
        this.workspaceItems = res.data.data;
        if (!this.isAdmin) {
          this.selectedWorkspaces = this.workspaceItems.map(
            (workspace) => workspace.workspaceId,
          );
        }
      });
    },
    collectUsers() {
      if (this.selectedWorkspaces.length === 0) {
        this.userItems = [];
        return;
      }
      const uniqueUsers = new Set();

      this.workspaceItems.forEach((workspace) => {
        if (this.selectedWorkspaces.includes(workspace.workspaceId)) {
          workspace.usernames.forEach((username) => {
            uniqueUsers.add(username);
          });
        }
      });

      this.userItems = Array.from(uniqueUsers).map((username) => username);
    },
  },
  beforeMount() {
    const queryParams = this.$route.query;
    if (queryParams?.rde) {
      this.showMyRde = true;
      this.selectedUsers = [this.userName];
      this.handleSearch();
    }
  },
  mounted() {
    this.getWorkspacesAndUsers();
  },
};
</script>

<style scoped>
.row {
  display: flex;
  margin-bottom: 20px;
}
.col {
  flex: 1;
  padding: 10px;
}
.pagination-controls {
  margin-top: 10px;
}
.total-label {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.0015em;
  text-align: left;
}
.total-value {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 0.0025em;
  text-align: left;
  color: #321fdb;
}
.search {
  max-width: 200px;
}

.buttons {
  /* color: rgba(19, 19, 22, 1); */
  color: var(--gray2);
  border: 1px solid rgba(19, 19, 22, 0.1) !important;
  &.v-btn__content {
    font-size: 14px;
    font-weight: normal !important;
    line-height: 16.41px;
    letter-spacing: 0.0089em;
    text-align: left;
  }
}
.columns {
  padding: 0 !important;
}
</style>
