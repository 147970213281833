<template>
  <div class="w-full rde-usage-wrapper flex flex-row gap-2">
    <div class="w-full">
      <div class="flex justify-between items-center panel-title">
        <h3 class="table-title pb-1">RDE Usage Overview</h3>
        <v-text-field
          v-model="searchKey"
          label="Filter"
          outlined
          dense
          clearable
          append-icon="mdi-filter"
          class="app-g-autocomplete-v2 flex-none"
          @click:append="searchKey = ''"
        ></v-text-field>
      </div>

      <v-data-table
        :headers="headers"
        :items="sortedItems"
        :hide-default-header="true"
        item-key="workspaceId"
        class="elevation-1 rde-usage-table w-full"
        :search="searchKey"
      >
        <template v-slot:header="{ props }">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="header-cell"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item, index }">
          <tr
            v-for="(usage, i) in item.usedOverview"
            :key="`usage-${item.rdeId + i}`"
            class="row-item"
            @click="onRowClick(item)"
          >
            <td v-if="i === 0" :rowspan="item.usedOverview.length">
              {{ item.rdeDisplayName }}
            </td>
            <td
              v-if="i === 0"
              :rowspan="item.usedOverview.length"
              :title="item.workspaceDisplayName"
            >
              {{ $helper.truncatedText(item.workspaceDisplayName, 40) }}
            </td>
            <td v-if="i === 0" :rowspan="item.usedOverview.length">
              {{ $helper.showDate(item.createdDate) }}
            </td>
            <td v-if="i === 0" :rowspan="item.usedOverview.length">
              {{ item.username }}
            </td>
            <td>{{ usage.instanceType }}</td>
            <td>{{ $helper.viewCpuData(usage.cpu) }}</td>
            <td>{{ $helper.viewMemoryData(usage.memory) }}</td>
            <td>
              {{ $helper.roundX(usage.disk || 0, 0) }}{{ usage.diskUnit }}
            </td>
            <td>{{ $helper.minutesToReadable(usage.estimatedTime) }}</td>
          </tr>
          <tr
            v-if="item.usedOverview.length === 0"
            :key="`no-usage-${index}`"
            class="row-item"
            @click="onRowClick(item)"
          >
            <td>{{ item.rdeDisplayName }}</td>
            <td :title="item.workspaceDisplayName">
              {{ $helper.truncatedText(item.workspaceDisplayName, 40) }}
            </td>
            <td>{{ $helper.showDate(item.createdDate) }}</td>
            <td>{{ item.username }}</td>
            <td colspan="5" class="text-center">No usage data</td>
          </tr>
        </template>
        <template v-slot:no-data>
          <div class="flex justify-center items-center h-20">
            No data available
          </div>
        </template>
      </v-data-table>
    </div>
    <div v-if="selectedRde" class="w-full">
      <div class="flex justify-between items-center panel-title">
        <h3 class="table-title pb-1">
          RDE Usage Detail:
          <v-chip small color="primary">
            {{ selectedRde?.rdeDisplayName }}
          </v-chip>
        </h3>
        <span
          class="text-sm text-primary cursor-pointer pr-2"
          @click="() => (selectedRde = null)"
          >Hide</span
        >
      </div>
      <v-data-table
        :headers="headersDetail"
        :items="rdeUsageDetail"
        :hide-default-header="true"
        item-key="rdeId"
        class="elevation-1 rde-usage-table w-full"
        :loading="loadingDetail"
      >
        <template v-slot:header="{ props }">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="header-cell"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.instanceType }}</td>
            <td>{{ $helper.viewCpuData(item.cpu) }}</td>
            <td>{{ $helper.viewMemoryData(item.memory) }}</td>
            <td>{{ $helper.roundX(item.disk || 0, 0) }} {{ item.diskUnit }}</td>
            <td>{{ item.storageType }}</td>
            <td>{{ $helper.minutesToReadable(item.estimatedTime) }}</td>
            <td>{{ $helper.showDate(item.updated) }}</td>
            <td>{{ $helper.showDate(item.endTime) }}</td>
          </tr>
        </template>
        <template v-slot:no-data>
          <div class="flex justify-center items-center h-20">
            No data available
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getRdeUsageDetail } from "@/service/apis/workspaceApis";

export default {
  props: {
    items: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dateRange: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loadingDetail: false,
      searchKey: "",
      headers: [
        { text: "RDE Name", value: "rdeDisplayName", sortable: false },
        {
          text: "Workspace Name",
          value: "workspaceDisplayName",
          sortable: false,
        },
        { text: "Created Time", value: "createdDate", sortable: false },
        { text: "Creator", value: "creator", sortable: false },
        { text: "Instance Type", value: "instanceType", sortable: false },
        { text: "CPU", value: "cpu", sortable: false },
        { text: "Memory", value: "memory", sortable: false },
        { text: "Disk", value: "disk", sortable: false },
        { text: "Estimated Time", value: "estimatedTime", sortable: false },
      ],
      headersDetail: [
        { text: "Instance Type", value: "instanceType", sortable: false },
        { text: "CPU", value: "cpu", sortable: false },
        { text: "Memory", value: "memory", sortable: false },
        { text: "Disk", value: "disk", sortable: false },
        { text: "Disk Type", value: "diskType", sortable: false },
        { text: "Used", value: "used", sortable: false },
        { text: "Created Time", value: "", sortable: false },
        { text: "End Time", value: "", sortable: false },
      ],
      rdeUsageDetail: [],
      selectedRde: null,
      from: "",
      to: "",
      //sortedItems: [],
    };
  },
  computed: {
    sortedItems() {
      return [...this.items].sort((a, b) => {
        return new Date(b.createdDate) - new Date(a.createdDate);
      });
    },
  },
  watch: {
    dateRange: {
      handler(newVal, oldVal) {
        if (newVal == oldVal || !newVal.startDate || !newVal.endDate) {
          return;
        }

        this.from = moment(newVal.startDate).format("YYYY-MM-DD");
        this.to = moment(newVal.endDate).format("YYYY-MM-DD");
      },
      deep: true,
    },
  },
  methods: {
    onRowClick(item) {
      if (this.selectedRde !== item) {
        if (item.usedOverview.length === 0) {
          return;
        }

        this.selectedRde = item;
        this.rdeUsageDetail = [];
        this.getRdeUsageDetail(item.rdeId);
      }
    },
    async getRdeUsageDetail(rdeId) {
      this.loadingDetail = true;
      try {
        // Call the API to get RDE usage detail
        const rdeUsageDetailResult = await getRdeUsageDetail({
          rdeIds: rdeId,
          from: this.from,
          to: this.to,
        });

        const dataArr = rdeUsageDetailResult?.data?.data[0] || [];
        this.rdeUsageDetail = dataArr.metring?.sort((a, b) => {
          return new Date(b.updated) - new Date(a.updated);
        });
      } catch (err) {
        console.error("Error getting RDE usage detail:", err);
      } finally {
        this.loadingDetail = false;
      }
    },
  },
  mounted() {
    this.from = moment(this.dateRange.startDate).format("YYYY-MM-DD");
    this.to = moment(this.dateRange.endDate).format("YYYY-MM-DD");
  },
};
</script>

<style type="scss" scoped>
.rde-usage-wrapper {
  .header-cell {
    /* font-weight: bold; */
  }
  .row-item {
    cursor: pointer;
  }
  .row-item:hover {
    background-color: #ebebeb !important;
  }
}
.elevation-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.header-cell {
  background-color: #e4e5eb;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.004em;
  text-align: left;
}
.table-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
}
.rde-usage-table {
  tr > td {
    font-size: 12px !important;
    font-weight: 400;
    letter-spacing: 0.004em;
    text-align: left;
  }
}

.panel-title {
  min-height: 45px;
}
</style>
